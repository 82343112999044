@charset "UTF-8";
/* -------- Style Application -------- */
/* -------- Variables -------- */
/* -------- Style extends -------- */
@import url(~slick-carousel/slick/slick.css);
@import url(~slick-carousel/slick/slick-theme.css);
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  animation-name: fadeOut; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  animation-name: fadeIn; }

.Toastify__toast {
  border-radius: 4px !important; }

/* -------- Reset style -------- */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  outline: none !important; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

html, body {
  min-height: 100%; }

body {
  background-color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #333; }

ol, ul {
  list-style: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button {
  outline: none !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  button:hover {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

a {
  text-decoration: none !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  a:hover {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }

input[type=text], textarea {
  -webkit-appearance: none; }

/* -------- Utility -------- */
.clearfix {
  clear: both; }

.m0 {
  margin: 0 !important; }

.mt0 {
  margin-top: 0 !important; }

.mr0 {
  margin-right: 0 !important; }

.mb0 {
  margin-bottom: 0 !important; }

.ml0 {
  margin-left: 0 !important; }

.m5 {
  margin: 5px !important; }

.mt5 {
  margin-top: 5px !important; }

.mr5 {
  margin-right: 5px !important; }

.mb5 {
  margin-bottom: 5px !important; }

.ml5 {
  margin-left: 5px !important; }

.m10 {
  margin: 10px !important; }

.mt10 {
  margin-top: 10px !important; }

.mr10 {
  margin-right: 10px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.ml10 {
  margin-left: 10px !important; }

.m15 {
  margin: 15px !important; }

.mt15 {
  margin-top: 15px !important; }

.mr15 {
  margin-right: 15px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.ml15 {
  margin-left: 15px !important; }

.m20 {
  margin: 20px !important; }

.mt20 {
  margin-top: 20px !important; }

.mr20 {
  margin-right: 20px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.ml20 {
  margin-left: 20px !important; }

.m25 {
  margin: 25px !important; }

.mt25 {
  margin-top: 25px !important; }

.mr25 {
  margin-right: 25px !important; }

.mb25 {
  margin-bottom: 25px !important; }

.ml25 {
  margin-left: 25px !important; }

.m30 {
  margin: 30px !important; }

.mt30 {
  margin-top: 30px !important; }

.mr30 {
  margin-right: 30px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.ml30 {
  margin-left: 30px !important; }

.m35 {
  margin: 35px !important; }

.mt35 {
  margin-top: 35px !important; }

.mr35 {
  margin-right: 35px !important; }

.mb35 {
  margin-bottom: 35px !important; }

.ml35 {
  margin-left: 35px !important; }

.m40 {
  margin: 40px !important; }

.mt40 {
  margin-top: 40px !important; }

.mr40 {
  margin-right: 40px !important; }

.mb40 {
  margin-bottom: 40px !important; }

.ml40 {
  margin-left: 40px !important; }

.m45 {
  margin: 45px !important; }

.mt45 {
  margin-top: 45px !important; }

.mr45 {
  margin-right: 45px !important; }

.mb45 {
  margin-bottom: 45px !important; }

.ml45 {
  margin-left: 45px !important; }

.m50 {
  margin: 50px !important; }

.mt50 {
  margin-top: 50px !important; }

.mr50 {
  margin-right: 50px !important; }

.mb50 {
  margin-bottom: 50px !important; }

.ml50 {
  margin-left: 50px !important; }

.m55 {
  margin: 55px !important; }

.mt55 {
  margin-top: 55px !important; }

.mr55 {
  margin-right: 55px !important; }

.mb55 {
  margin-bottom: 55px !important; }

.ml55 {
  margin-left: 55px !important; }

.m60 {
  margin: 60px !important; }

.mt60 {
  margin-top: 60px !important; }

.mr60 {
  margin-right: 60px !important; }

.mb60 {
  margin-bottom: 60px !important; }

.ml60 {
  margin-left: 60px !important; }

.m65 {
  margin: 65px !important; }

.mt65 {
  margin-top: 65px !important; }

.mr65 {
  margin-right: 65px !important; }

.mb65 {
  margin-bottom: 65px !important; }

.ml65 {
  margin-left: 65px !important; }

.p0 {
  padding: 0 !important; }

.pt0 {
  padding-top: 0 !important; }

.pr0 {
  padding-right: 0 !important; }

.pb0 {
  padding-bottom: 0 !important; }

.pl0 {
  padding-left: 0 !important; }

.p5 {
  padding: 5px !important; }

.pt5 {
  padding-top: 5px !important; }

.pr5 {
  padding-right: 5px !important; }

.pb5 {
  padding-bottom: 5px !important; }

.pl5 {
  padding-left: 5px !important; }

.p10 {
  padding: 10px !important; }

.pt10 {
  padding-top: 10px !important; }

.pr10 {
  padding-right: 10px !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pl10 {
  padding-left: 10px !important; }

.p15 {
  padding: 15px !important; }

.pt15 {
  padding-top: 15px !important; }

.pr15 {
  padding-right: 15px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.pl15 {
  padding-left: 15px !important; }

.p20 {
  padding: 20px !important; }

.pt20 {
  padding-top: 20px !important; }

.pr20 {
  padding-right: 20px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pl20 {
  padding-left: 20px !important; }

.p25 {
  padding: 25px !important; }

.pt25 {
  padding-top: 25px !important; }

.pr25 {
  padding-right: 25px !important; }

.pb25 {
  padding-bottom: 25px !important; }

.pl25 {
  padding-left: 25px !important; }

.p30 {
  padding: 30px !important; }

.pt30 {
  padding-top: 30px !important; }

.pr30 {
  padding-right: 30px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.pl30 {
  padding-left: 30px !important; }

.p35 {
  padding: 35px !important; }

.pt35 {
  padding-top: 35px !important; }

.pr35 {
  padding-right: 35px !important; }

.pb35 {
  padding-bottom: 35px !important; }

.pl35 {
  padding-left: 35px !important; }

.p40 {
  padding: 40px !important; }

.pt40 {
  padding-top: 40px !important; }

.pr40 {
  padding-right: 40px !important; }

.pb40 {
  padding-bottom: 40px !important; }

.pl40 {
  padding-left: 40px !important; }

.p45 {
  padding: 45px !important; }

.pt45 {
  padding-top: 45px !important; }

.pr45 {
  padding-right: 45px !important; }

.pb45 {
  padding-bottom: 45px !important; }

.pl45 {
  padding-left: 45px !important; }

.p50 {
  padding: 50px !important; }

.pt50 {
  padding-top: 50px !important; }

.pr50 {
  padding-right: 50px !important; }

.pb50 {
  padding-bottom: 50px !important; }

.pl50 {
  padding-left: 50px !important; }

.p55 {
  padding: 55px !important; }

.pt55 {
  padding-top: 55px !important; }

.pr55 {
  padding-right: 55px !important; }

.pb55 {
  padding-bottom: 55px !important; }

.pl55 {
  padding-left: 55px !important; }

.p60 {
  padding: 60px !important; }

.pt60 {
  padding-top: 60px !important; }

.pr60 {
  padding-right: 60px !important; }

.pb60 {
  padding-bottom: 60px !important; }

.pl60 {
  padding-left: 60px !important; }

.p65 {
  padding: 65px !important; }

.pt65 {
  padding-top: 65px !important; }

.pr65 {
  padding-right: 65px !important; }

.pb65 {
  padding-bottom: 65px !important; }

.pl65 {
  padding-left: 65px !important; }

.min-width-0 {
  min-width: 0; }

.min-width-10 {
  min-width: 10px; }

.min-width-20 {
  min-width: 20px; }

.min-width-30 {
  min-width: 30px; }

.min-width-40 {
  min-width: 40px; }

.min-width-50 {
  min-width: 50px; }

.min-width-60 {
  min-width: 60px; }

.min-width-70 {
  min-width: 70px; }

.min-width-80 {
  min-width: 80px; }

.min-width-90 {
  min-width: 90px; }

.min-width-100 {
  min-width: 100px; }

.min-width-110 {
  min-width: 110px; }

.min-width-120 {
  min-width: 120px; }

.min-width-130 {
  min-width: 130px; }

.min-width-140 {
  min-width: 140px; }

.min-width-150 {
  min-width: 150px; }

.min-width-160 {
  min-width: 160px; }

.min-width-170 {
  min-width: 170px; }

.min-width-180 {
  min-width: 180px; }

.min-width-190 {
  min-width: 190px; }

.min-width-200 {
  min-width: 200px; }

.min-width-210 {
  min-width: 210px; }

.min-width-220 {
  min-width: 220px; }

.min-width-230 {
  min-width: 230px; }

.min-width-240 {
  min-width: 240px; }

.min-width-250 {
  min-width: 250px; }

.min-width-260 {
  min-width: 260px; }

.min-width-270 {
  min-width: 270px; }

.min-width-280 {
  min-width: 280px; }

.min-width-290 {
  min-width: 290px; }

.min-width-300 {
  min-width: 300px; }

.min-width-310 {
  min-width: 310px; }

.min-width-320 {
  min-width: 320px; }

.min-width-330 {
  min-width: 330px; }

.min-width-340 {
  min-width: 340px; }

.min-width-350 {
  min-width: 350px; }

.min-width-360 {
  min-width: 360px; }

.min-width-370 {
  min-width: 370px; }

.min-width-380 {
  min-width: 380px; }

.min-width-390 {
  min-width: 390px; }

.min-width-400 {
  min-width: 400px; }

.min-width-410 {
  min-width: 410px; }

.min-width-420 {
  min-width: 420px; }

.min-width-430 {
  min-width: 430px; }

.min-width-440 {
  min-width: 440px; }

.min-width-450 {
  min-width: 450px; }

.min-width-460 {
  min-width: 460px; }

.min-width-470 {
  min-width: 470px; }

.min-width-480 {
  min-width: 480px; }

.min-width-490 {
  min-width: 490px; }

.min-width-500 {
  min-width: 500px; }

.max-width-0 {
  max-width: 0; }

.max-width-5 {
  max-width: 5px; }

.max-width-10 {
  max-width: 10px; }

.max-width-15 {
  max-width: 15px; }

.max-width-20 {
  max-width: 20px; }

.max-width-25 {
  max-width: 25px; }

.max-width-30 {
  max-width: 30px; }

.max-width-35 {
  max-width: 35px; }

.max-width-40 {
  max-width: 40px; }

.max-width-45 {
  max-width: 45px; }

.max-width-50 {
  max-width: 50px; }

.max-width-55 {
  max-width: 55px; }

.max-width-60 {
  max-width: 60px; }

.max-width-65 {
  max-width: 65px; }

.max-width-70 {
  max-width: 70px; }

.max-width-75 {
  max-width: 75px; }

.max-width-80 {
  max-width: 80px; }

.max-width-85 {
  max-width: 85px; }

.max-width-90 {
  max-width: 90px; }

.max-width-95 {
  max-width: 95px; }

.max-width-100 {
  max-width: 100px; }

.max-width-105 {
  max-width: 105px; }

.max-width-110 {
  max-width: 110px; }

.max-width-115 {
  max-width: 115px; }

.max-width-120 {
  max-width: 120px; }

.max-width-125 {
  max-width: 125px; }

.max-width-130 {
  max-width: 130px; }

.max-width-135 {
  max-width: 135px; }

.max-width-140 {
  max-width: 140px; }

.max-width-145 {
  max-width: 145px; }

.max-width-150 {
  max-width: 150px; }

.max-width-155 {
  max-width: 155px; }

.max-width-160 {
  max-width: 160px; }

.max-width-165 {
  max-width: 165px; }

.max-width-170 {
  max-width: 170px; }

.max-width-175 {
  max-width: 175px; }

.max-width-180 {
  max-width: 180px; }

.max-width-185 {
  max-width: 185px; }

.max-width-190 {
  max-width: 190px; }

.max-width-195 {
  max-width: 195px; }

.max-width-200 {
  max-width: 200px; }

.max-width-205 {
  max-width: 205px; }

.max-width-210 {
  max-width: 210px; }

.max-width-215 {
  max-width: 215px; }

.max-width-220 {
  max-width: 220px; }

.max-width-225 {
  max-width: 225px; }

.max-width-230 {
  max-width: 230px; }

.max-width-235 {
  max-width: 235px; }

.max-width-240 {
  max-width: 240px; }

.max-width-245 {
  max-width: 245px; }

.max-width-250 {
  max-width: 250px; }

.max-width-255 {
  max-width: 255px; }

.max-width-260 {
  max-width: 260px; }

.max-width-265 {
  max-width: 265px; }

.max-width-270 {
  max-width: 270px; }

.max-width-275 {
  max-width: 275px; }

.max-width-280 {
  max-width: 280px; }

.max-width-285 {
  max-width: 285px; }

.max-width-290 {
  max-width: 290px; }

.max-width-295 {
  max-width: 295px; }

.max-width-300 {
  max-width: 300px; }

.max-width-305 {
  max-width: 305px; }

.max-width-310 {
  max-width: 310px; }

.max-width-315 {
  max-width: 315px; }

.max-width-320 {
  max-width: 320px; }

.max-width-325 {
  max-width: 325px; }

.max-width-330 {
  max-width: 330px; }

.max-width-335 {
  max-width: 335px; }

.max-width-340 {
  max-width: 340px; }

.max-width-345 {
  max-width: 345px; }

.max-width-350 {
  max-width: 350px; }

.max-width-355 {
  max-width: 355px; }

.max-width-360 {
  max-width: 360px; }

.max-width-365 {
  max-width: 365px; }

.max-width-370 {
  max-width: 370px; }

.max-width-375 {
  max-width: 375px; }

.max-width-380 {
  max-width: 380px; }

.max-width-385 {
  max-width: 385px; }

.max-width-390 {
  max-width: 390px; }

.max-width-395 {
  max-width: 395px; }

.max-width-400 {
  max-width: 400px; }

.max-width-405 {
  max-width: 405px; }

.max-width-410 {
  max-width: 410px; }

.max-width-415 {
  max-width: 415px; }

.max-width-420 {
  max-width: 420px; }

.max-width-425 {
  max-width: 425px; }

.max-width-430 {
  max-width: 430px; }

.max-width-435 {
  max-width: 435px; }

.max-width-440 {
  max-width: 440px; }

.max-width-445 {
  max-width: 445px; }

.max-width-450 {
  max-width: 450px; }

.max-width-455 {
  max-width: 455px; }

.max-width-460 {
  max-width: 460px; }

.max-width-465 {
  max-width: 465px; }

.max-width-470 {
  max-width: 470px; }

.max-width-475 {
  max-width: 475px; }

.max-width-480 {
  max-width: 480px; }

.max-width-485 {
  max-width: 485px; }

.max-width-490 {
  max-width: 490px; }

.max-width-495 {
  max-width: 495px; }

.max-width-500 {
  max-width: 500px; }

.text-center, .center {
  text-align: center; }

.text-left, .left {
  text-align: left; }

.text-right, .right {
  text-align: right; }

.no-border {
  border: none !important;
  box-shadow: none !important; }

/* -------- Style button -------- */
.ant-btn:not(.ant-btn-link) {
  height: 36px; }

.ant-btn:hover:not(.ant-btn-link), .ant-btn:active:not(.ant-btn-link), .ant-btn:focus:not(.ant-btn-link) {
  border-color: #d9d9d9;
  opacity: 0.8; }

.ant-btn:hover.ant-btn-link, .ant-btn:active.ant-btn-link, .ant-btn:focus.ant-btn-link {
  color: initial; }
  .ant-btn:hover.ant-btn-link.color-blue, .ant-btn:active.ant-btn-link.color-blue, .ant-btn:focus.ant-btn-link.color-blue {
    color: #0275D8; }
  .ant-btn:hover.ant-btn-link.color-red, .ant-btn:active.ant-btn-link.color-red, .ant-btn:focus.ant-btn-link.color-red {
    color: #d5313d; }
  .ant-btn:hover.ant-btn-link.color-green, .ant-btn:active.ant-btn-link.color-green, .ant-btn:focus.ant-btn-link.color-green {
    color: #28A745; }

.btn-normal {
  min-width: 90px;
  font-size: 12px;
  font-weight: 500;
  border-color: #d9d9d9; }
  .btn-normal.blue {
    color: #fff;
    background: #0053A7;
    border: none; }
    .btn-normal.blue:hover, .btn-normal.blue:active, .btn-normal.blue:focus {
      background: #0053A7;
      color: #fff; }
  .btn-normal.green {
    color: #fff;
    background: #28A745;
    border: none; }
    .btn-normal.green:hover, .btn-normal.green:active, .btn-normal.green:focus {
      background: #28A745;
      color: #fff; }
  .btn-normal.orange {
    color: #fff;
    background: #F9A204;
    border: none; }
    .btn-normal.orange:hover, .btn-normal.orange:active, .btn-normal.orange:focus {
      background: #F9A204;
      color: #fff; }
  .btn-normal.ecstasy {
    color: #fff;
    background: #F36716;
    border: none; }
    .btn-normal.ecstasy:hover, .btn-normal.ecstasy:active, .btn-normal.ecstasy:focus {
      background: #F36716;
      color: #fff; }
  .btn-normal.turquoise {
    color: #fff;
    background: #17A2B8;
    border: none; }
    .btn-normal.turquoise .icon:before {
      color: #fff; }
    .btn-normal.turquoise:hover, .btn-normal.turquoise:active, .btn-normal.turquoise:focus {
      background: #17A2B8;
      color: #fff; }
  .btn-normal.red {
    color: #fff;
    background: #dc4f5c;
    border: none; }
    .btn-normal.red .icon:before {
      color: #fff; }
    .btn-normal.red:hover, .btn-normal.red:active, .btn-normal.red:focus {
      background: #dc4f5c;
      color: #fff; }
  .btn-normal.btn-min-width-auto {
    min-width: auto !important; }

.btn-uppercase {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase; }

.btn-cancel {
  background-color: #F7F7F7; }
  .btn-cancel:hover, .btn-cancel:active, .btn-cancel:focus {
    background: #F7F7F7;
    color: #5c6376; }
  .btn-cancel.ant-btn[disabled] {
    background-color: #F7F7F7;
    color: #5c6376; }

.btn-primary {
  background-color: #0053A7;
  color: #fff; }
  .btn-primary:hover, .btn-primary:active, .btn-primary:focus {
    background: #0053A7;
    color: #fff; }

.btn-submit {
  color: #fff;
  background: #0275D8;
  border: none; }
  .btn-submit:hover, .btn-submit:active, .btn-submit:focus {
    background: #0275D8;
    color: #fff; }

.btn-submit-group {
  text-align: center; }
  .btn-submit-group.right {
    text-align: right; }
  .btn-submit-group .btn-normal {
    padding: 0 10px; }

.content-btn {
  display: inline-block; }
  .content-btn .name {
    padding-left: 5px;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    line-height: 1.5; }
  .content-btn .icon {
    font-size: 13px;
    vertical-align: middle; }
  .content-btn .icon-antd {
    font-size: 15px;
    vertical-align: middle; }

.btn-upload {
  background-color: #f5f5f5; }
  .btn-upload:hover, .btn-upload:active, .btn-upload:focus {
    background: #f5f5f5;
    color: #000; }

.btn-loading {
  cursor: default;
  opacity: 0.8; }

.btn-left {
  margin-right: 10px; }

.btn-view {
  background-color: #25b947;
  color: #fff;
  border: none; }
  .btn-view:hover, .btn-view:active, .btn-view:focus {
    background: #25b947;
    color: #fff; }

.btn-edit {
  background-color: #17A2B8;
  color: #fff;
  border: none; }
  .btn-edit:hover, .btn-edit:active, .btn-edit:focus {
    background: #17A2B8;
    color: #fff; }

.btn-delete {
  background-color: #DC4F5C;
  color: #fff;
  border: none; }
  .btn-delete:hover, .btn-delete:active, .btn-delete:focus {
    background: #DC4F5C;
    color: #fff; }

.btn-pl0 .name {
  padding-left: 0px; }

/* -------- Style input -------- */
/* -------- Style about table -------- */
.table-wrapper-custom {
  overflow: auto !important; }
  .table-wrapper-custom.table-wrapper-nopadding {
    padding: 0px; }
  .table-wrapper-custom.has-on-row {
    cursor: pointer; }
  .table-wrapper-custom .ant-table table {
    border-radius: 0; }
  .table-wrapper-custom:not(.default-column-size) .ant-table-thead > tr > th {
    padding: 10px;
    line-height: 1.5; }
  .table-wrapper-custom:not(.default-column-size) .ant-table-tbody > tr > td {
    padding: 5px 10px;
    line-height: 2; }
  .table-wrapper-custom.table-thead-uppercase .ant-table-thead > tr > th {
    text-transform: uppercase;
    font-size: 12px; }

.ant-table-body-outer {
  margin-bottom: 0; }

.table-and-form .ant-form-item {
  margin-bottom: 0px !important; }

.table-group .cell {
  height: 40px;
  padding: 5px 10px;
  line-height: 2;
  text-align: left; }
  .table-group .cell.center {
    text-align: center; }

.table-group .cell-group {
  font-weight: 500;
  display: block; }

.table-group .cell-background {
  background-color: aliceblue; }

.table-group .row-parent {
  background-color: aliceblue;
  font-weight: 500; }

.table-group .row-total {
  color: #000;
  background-color: #F7F7F7;
  font-weight: 500; }
  .table-group .row-total > td {
    padding: 10px !important;
    line-height: 1.5; }

.table-group.no-hover tr:hover td {
  background: initial !important; }

.table-group.no-boder-top .ant-table table {
  border-top: 0; }

.table-hangmuc {
  font-weight: normal; }

/* -------- Style form -------- */
.form-container.form-content {
  padding: 10px 5px 10px 20px; }

.form-item {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #3A3C41;
  text-align: left;
  margin-bottom: 10px; }
  .form-item .ant-select-selection {
    font-weight: normal; }
  .form-item .form-item-span {
    font-size: 14px;
    color: #3A3C41; }
  .form-item .ant-form-item-label > label.ant-form-item-required::before {
    content: none !important; }
  .form-item .ant-form-item-label > label.ant-form-item-required::after {
    display: inline-block;
    margin-right: 0;
    margin-left: 4px;
    color: #d5313d;
    font-size: 14px;
    line-height: 1;
    content: '*' !important; }
  @media only screen and (max-width: 767px) {
    .form-item.form-item-label-empty .ant-form-item-label {
      display: none; } }
  .form-item.form-item-not-label {
    margin-bottom: 15px; }

.footer-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  border-top: 1px solid #E2E2E2;
  padding: 20px 15px 20px 15px;
  background-color: #fff;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .footer-form .ant-btn-loading {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center; }
    .footer-form .ant-btn-loading .anticon-loading {
      margin: 0 10px 0 0; }

.form-action {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  border-top: 1px solid #E2E2E2;
  border-bottom: 1px solid #E2E2E2;
  padding: 10px 20px 0;
  background-color: #fff;
  margin-bottom: -1px;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .form-action .ant-btn-loading {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center; }
    .form-action .ant-btn-loading .anticon-loading {
      margin: 0 10px 0 0; }

.btn-group {
  margin: 0 -5px; }
  .btn-group .btn-common {
    padding: 0 5px;
    margin-bottom: 10px;
    display: inline-block; }

.ant-form-item-label {
  line-height: 30px; }

.ant-form-item-label label,
.form-item-span {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #3A3C41;
  text-align: left; }
  .ant-form-item-label label.has-form-item-span,
  .form-item-span.has-form-item-span {
    font-weight: normal; }

.form-value-label {
  display: inline-block;
  margin-top: 5px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #3A3C41;
  text-align: left;
  line-height: 1.4; }
  .form-value-label.label-required:after {
    display: inline-block;
    margin-right: 0;
    margin-left: 4px;
    color: #d5313d;
    font-size: 14px;
    line-height: 1;
    content: '*' !important; }

.form-value-item {
  display: block; }

.color-required {
  color: #d5313d; }

.color-orange {
  color: #F9A204; }

.color-green {
  color: #28A745; }

.color-primary {
  color: #0275D8; }

.form-item-no-label .ant-form-item-label {
  opacity: 0; }

.ant-form-item-explain {
  font-weight: 400;
  padding-top: 10px;
  white-space: pre-line; }

.input-large {
  width: 400px; }

.select-medium {
  width: 230px; }

.select-small {
  width: 155px; }

.form-group .heading-2 {
  padding: 10px 20px;
  border-bottom: 1px solid #F4F6F8;
  font-size: 18px; }

.form-group .form-group-content {
  padding: 10px 20px;
  border-bottom: 1px solid #F4F6F8; }

.form-group:last-child .form-group-content {
  border-bottom: 0; }

.form-group:not(:first-child) .heading-2 {
  border-top: 1px solid #D1D5D7; }

.form-page .b-normal-wrapper .body-wrapper {
  padding: 15px 20px 10px; }

.form-wrapper-list .b-table-footer {
  border: 1px solid #d9d9d9;
  border-top: 0; }

.form-wrapper-list .ant-empty {
  padding: 0; }

.form-wrapper-list .ant-empty-normal {
  margin: 0; }

.ant-input-affix-wrapper .ant-input-suffix {
  font-weight: 400;
  color: #c5c1d2; }

/* -------- Style text -------- */
.text-uppercase {
  text-transform: uppercase; }

.font-weight-500 {
  font-weight: 500; }

.font-weight-600 {
  font-weight: 600; }

.font-size-18 {
  font-size: 18px; }

.font-size-20 {
  font-size: 20px; }

.font-size-11 {
  font-size: 11px !important; }

.font-size-13 {
  font-size: 13px !important; }

.font-size-16 {
  font-size: 16px; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

/* -------- Style color -------- */
.color-blue {
  color: #0275D8; }

.color-red {
  color: #d5313d; }

.color-black {
  color: #000; }

.color-green {
  color: #28A745; }

.color-orange {
  color: #F9A204; }

.color-light-blue {
  color: #167dd6; }

.color-purple {
  color: #531DAB; }

.color-ecstasy {
  color: #F36716; }

.b-color-blue {
  background-color: #0053A7; }

.b-color-pink {
  background-color: #ff3399; }

.b-color-gray {
  background-color: #F7F7F7; }

/* -------- Style theme -------- */
/* -------- Style custom Antd -------- */
.ant-empty {
  padding: 20px; }

.notifi-success {
  background-color: #D4EDDA; }
  .notifi-success .ant-notification-notice-message {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    color: #155723; }
  .notifi-success .ant-notification-notice-description {
    color: #155723; }
  .notifi-success .ant-notification-notice-close {
    color: #155723; }

.notifi-warning {
  background-color: #FFF3CD; }
  .notifi-warning .ant-notification-notice-message {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    color: #9A5E07; }
  .notifi-warning .ant-notification-notice-description {
    color: #9A5E07; }
  .notifi-warning .ant-notification-notice-close {
    color: #9A5E07; }

.notifi-error {
  background-color: #F8D7DA; }
  .notifi-error .ant-notification-notice-message {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    color: #3C0700; }
  .notifi-error .ant-notification-notice-description {
    color: #3C0700; }
  .notifi-error .ant-notification-notice-close {
    color: #3C0700; }

.ant-checkbox-wrapper {
  font-weight: 400; }

.ant-table-tbody .ant-checkbox-checked .ant-checkbox-inner::after {
  margin-left: 0.5px; }

.ant-checkbox-inner {
  width: 20px;
  height: 20px;
  position: unset; }
  .ant-checkbox-inner:after {
    left: 27%; }

.ant-table-tbody .ant-checkbox-checked .ant-checkbox-inner::after {
  margin-left: 0px; }

.ant-checkbox-checked .ant-checkbox-inner::after {
  margin-top: -1px; }

.ant-input-search.ant-input-affix-wrapper {
  text-align: left; }

.ant-select-dropdown-menu-item {
  white-space: normal;
  text-overflow: initial; }

.ant-select-selection--multiple {
  max-height: 100px;
  overflow-y: auto;
  overflow-x: hidden; }

/* -------- Global style -------- */
#root {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body, html {
  height: 100%; }

html {
  scroll-behavior: smooth; }

body {
  background-color: #edf2f6;
  overflow-x: hidden; }

.hide {
  display: none; }

.no-shadow {
  box-shadow: none; }

.d-block {
  display: block; }

.justify-content-center {
  justify-content: center; }

.column-25 {
  float: left;
  width: 25%; }

.w-0 {
  width: 0 !important; }

.size18 {
  font-size: 18px; }

.size24 {
  font-size: 24px; }

.ml-60 {
  margin-left: 60px; }

.ml-80 {
  margin-left: 80px; }

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both; }

.font-family-bold {
  font-family: "Roboto", sans-serif;
  font-weight: bold; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.cursor-default {
  cursor: default; }

.cursor-pointer {
  cursor: pointer; }

.w-100 {
  width: 100%; }

.flex-wrap-nowrap {
  flex-wrap: nowrap; }

.d-flex {
  display: flex; }

.d-inline-block {
  display: inline-block; }

.flex-direction-column {
  flex-direction: column; }

.border-bottom {
  border-bottom: 1px solid #e8e8e8; }

.border-none {
  border: none !important; }

.inline-block {
  display: inline-block !important; }

.pre-line {
  white-space: pre-line !important; }

.link-underline span {
  text-decoration: underline; }

.text-normal {
  font-weight: normal; }

.bold {
  font-weight: 700; }

.hand {
  cursor: pointer; }

.pre-line {
  white-space: pre-line; }

.image-medium {
  width: 200px;
  height: auto; }

.description {
  font-size: 15px;
  padding: 10px 0; }

.error-message {
  margin: 10px 0;
  color: #d5313d; }

.b-container {
  padding-right: 15px;
  padding-left: 15px;
  margin: 90px auto 0;
  width: 1300px; }

.loading-container {
  text-align: center;
  margin-top: 30px;
  padding: 30px 50px; }

.icon-left {
  padding-right: 10px; }

.icon-small {
  font-size: 12px; }

.icon-medium {
  font-size: 18px; }

.b-normal-wrapper {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  -webkit-box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.06); }
  .b-normal-wrapper .heading-wrapper {
    background-color: #fff;
    line-height: 2;
    padding: 10px 20px;
    border-bottom: 1px solid #F4F6F8; }
    .b-normal-wrapper .heading-wrapper .heading-right {
      text-align: right; }
  .b-normal-wrapper .body-wrapper {
    background-color: #fff;
    padding: 20px;
    overflow-x: auto; }
  .b-normal-wrapper .footer-wrapper {
    border-top: 1px solid #F4F6F8;
    padding: 10px 20px;
    background-color: rgba(244, 247, 251, 0.5); }

.wrapper-srcoll-x {
  width: 100%;
  overflow: auto; }
  .wrapper-srcoll-x .ant-table-thead > tr > th {
    padding: 10px;
    line-height: 1.5; }
  .wrapper-srcoll-x .ant-table-tbody > tr > td {
    padding: 5px 10px;
    line-height: 1.5; }

.heading-search {
  width: 100%;
  display: inline-block; }
  .heading-search .b-search {
    text-align: right;
    display: inline-block;
    margin-right: 5px; }
  .heading-search .heading-search-item {
    display: inline-block;
    padding: 0 5px;
    max-width: 500px; }
  .heading-search .ant-input-search-icon {
    color: #d5313d;
    font-weight: 700;
    font-size: 18px; }
  .heading-search .heading3 {
    line-height: 25px;
    padding: 15px 0;
    font-size: 16px; }

.b-action .link {
  font-weight: 500; }

.b-action .seperator {
  width: 1px;
  border-right: 2px solid rgba(0, 0, 0, 0.63); }

.ant-tooltip-inner {
  background-color: #797979 !important;
  padding: 12px 20px !important;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.06);
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #fff;
  letter-spacing: 0; }
  .ant-tooltip-inner .icon {
    padding: 10px; }
    .ant-tooltip-inner .icon:before {
      color: #fff; }

.ant-tooltip-arrow:before {
  background-color: #797979 !important; }

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
  border-radius: 5px; }

::-webkit-scrollbar {
  background-color: #F5F5F5; }
  ::-webkit-scrollbar:horizontal {
    height: 10px; }
  ::-webkit-scrollbar:vertical {
    width: 10px; }

::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 5px; }

.description-table {
  font-size: 13px;
  color: #292A2D;
  line-height: 2; }

.right-pagination {
  text-align: right; }

.content-wrapper > .content-notfound {
  height: 71vh;
  min-height: 71vh;
  background-color: #fff; }

.content-notfound {
  text-align: center;
  height: 36vh;
  min-height: 100%;
  position: relative; }
  .content-notfound .center-block {
    vertical-align: middle;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: auto;
    transform: translate(-50%, -50%); }
  .content-notfound .bg-notdata {
    max-width: 80px; }
    @media only screen and (max-width: 768px) {
      .content-notfound .bg-notdata {
        max-width: 60px; } }
  .content-notfound .heading {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    margin-top: 20px;
    color: #333;
    letter-spacing: 0;
    text-align: center;
    line-height: 1.4; }
    @media only screen and (max-width: 768px) {
      .content-notfound .heading {
        font-size: 15px; } }

.content-notfound.small-no-data {
  height: 25vh;
  min-height: 100%; }
  .content-notfound.small-no-data.content-notfound .bg-notdata {
    max-width: 80px; }
  .content-notfound.small-no-data.content-notfound .heading {
    font-size: 15px;
    margin-top: 10px; }

.content-wrapper > .small-no-data {
  height: 25vh;
  min-height: 100%; }
  .content-wrapper > .small-no-data.content-notfound .bg-notdata {
    max-width: 80px; }
  .content-wrapper > .small-no-data.content-notfound .heading {
    font-size: 15px;
    margin-top: 10px; }

.b-avatar-upload .b-btn-upload {
  padding: 20px 30px; }

tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff; }

tr.drop-over-upward td {
  border-top: 2px dashed #1890ff; }

.b-file-view {
  cursor: pointer; }

.files-upload-block .decription {
  font-style: italic;
  color: gray;
  font-size: 13px;
  font-weight: 500; }

.files-upload-block .btn-upload {
  margin-right: 10px; }

.files-upload-block .list-files {
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  padding: 0 10px; }

.b-search-wrapper {
  background-color: transparent;
  margin-bottom: 20px; }
  .b-search-wrapper .body-wrapper {
    padding: 10px 20px; }
  .b-search-wrapper .footer-wrapper {
    text-align: right;
    background-color: #fff; }

.box-status .tag-status {
  padding: 2px;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  color: #000; }

.box-status .text-status {
  color: #000; }

.box-status .time {
  color: #000;
  margin-top: 5px; }

.box-status.done .tag-status {
  border: 1px solid #28A745;
  color: #28A745; }

.box-status.done .text-status {
  color: #0275D8; }

.box-status.progress .tag-status {
  border: 1px solid #d5313d;
  color: #d5313d; }

.box-status.progress .text-status {
  color: #d5313d; }

.scrollbox {
  max-width: 100%;
  max-height: 10em;
  overflow: auto;
  visibility: hidden; }

.scrollbox-content,
.scrollbox:hover,
.scrollbox:focus {
  visibility: visible; }

.scrollbox-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  height: 100%; }
  .scrollbox-content.large {
    -webkit-box-align: inherit;
    -ms-flex-align: inherit;
    -webkit-align-items: inherit;
    -moz-align-items: inherit;
    align-items: inherit; }

.scrollbox-delayed {
  transition: visibility 0.2s; }

.scrollbox-delayed:hover {
  transition: visibility 0s 0.2s; }

.modal-page .ant-modal-header {
  padding: 20px; }

.modal-page .modal-page-title .icon::before,
.modal-page .modal-page-title .icon-antd {
  color: #0053A7; }

.modal-page .modal-page-title .name {
  color: #0053A7;
  padding-left: 5px; }

.box-trigger-wrapper {
  display: block;
  border-radius: 4px;
  border: 1px solid #0275D8;
  color: #000;
  cursor: pointer;
  transition: box-shadow .3s; }
  .box-trigger-wrapper .box-trigger-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    height: 10em;
    min-width: 75px; }
  .box-trigger-wrapper:hover {
    -webkit-box-shadow: 0 5px 11px 0 rgba(33, 33, 33, 0.2);
    -moz-box-shadow: 0 5px 11px 0 rgba(33, 33, 33, 0.2);
    box-shadow: 0 5px 11px 0 rgba(33, 33, 33, 0.2); }

.select-trigger {
  min-width: 170px !important; }
  .select-trigger .ant-select-selection {
    -webkit-box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.06);
    border-color: #0275D8; }
    .select-trigger .ant-select-selection:hover {
      -webkit-box-shadow: 0 5px 11px 0 rgba(33, 33, 33, 0.2);
      -moz-box-shadow: 0 5px 11px 0 rgba(33, 33, 33, 0.2);
      box-shadow: 0 5px 11px 0 rgba(33, 33, 33, 0.2); }

.none-selectable > .ant-select-tree-node-content-wrapper .ant-select-tree-title {
  cursor: not-allowed;
  opacity: .4; }

.hidden-checkbox-tree > .ant-select-tree-checkbox {
  display: none; }

.hidden-node {
  display: none; }

.tree-dropdown {
  max-width: 300px;
  max-height: 400px !important;
  overflow: auto; }
  .tree-dropdown .ant-select-tree li .ant-select-tree-node-content-wrapper {
    width: initial; }

.heading {
  margin: 10px 0; }

.wrapper-loadmore {
  border: 1px solid #f0f0f0;
  margin-top: -1px;
  padding: 10px 20px;
  text-align: center; }

.block-right-button {
  margin: 10px 0px;
  display: flex;
  justify-content: flex-end; }

/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
.layout-container {
  min-height: 100vh; }
  .layout-container.closed-sidebar .logo-container {
    padding: 9px; }
    .layout-container.closed-sidebar .logo-container .img-logo {
      width: 40px; }
    .layout-container.closed-sidebar .logo-container .btn-menu {
      left: 50%;
      right: unset; }
  .layout-container.closed-sidebar .wrapper-content-right {
    margin-left: 60px; }
  .layout-container.closed-sidebar .fixed-header {
    width: calc(100% - 60px); }
  .layout-container.closed-sidebar .list-menu-sidebar {
    width: 60px !important; }

.logo-container {
  background-color: #0B3D91;
  height: 60px;
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  width: 100%;
  padding-left: 24px; }
  .logo-container .title {
    font-family: 'UTMAvo';
    font-size: 10px;
    color: #fff;
    width: 130px;
    margin-left: 10px;
    max-width: calc(100% - 100px);
    line-height: 1.5;
    margin-bottom: 0; }
  .logo-container .img-logo {
    width: 40px; }
  .logo-container .btn-menu {
    position: absolute;
    right: 15px;
    transform: translate(-50%, -50%);
    top: 50%;
    color: #fff;
    cursor: pointer; }

.wrapper-content-right {
  margin-left: 260px;
  width: 100%;
  margin-top: 60px;
  min-height: calc(100vh - 60px);
  transition: all 0.2s ease-in-out; }
  .wrapper-content-right .container-content {
    margin-top: auto; }

/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
.container-heading {
  margin-bottom: 20px;
  border-top: 2px solid #e8e8e8; }
  .container-heading .heading-page {
    background-color: #fff; }
    .container-heading .heading-page .ant-page-header {
      padding: 15px 24px; }
    .container-heading .heading-page .ant-page-header-heading-title {
      font-size: 20px;
      float: none; }
      .container-heading .heading-page .ant-page-header-heading-title .ant-typography {
        color: inherit;
        margin: 0; }

.heading-tab {
  width: 100%;
  padding: 5px 15px;
  background: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9; }
  .heading-tab .heading-tab-item {
    display: inline-block;
    cursor: pointer;
    padding: 10px; }
    .heading-tab .heading-tab-item .name {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 19px;
      text-transform: uppercase;
      font-weight: 500; }
    .heading-tab .heading-tab-item.active .name,
    .heading-tab .heading-tab-item.active .icon:before {
      color: #0275D8; }
    .heading-tab .heading-tab-item.active .icon-antd {
      color: #0275D8; }
  .heading-tab .heading-tab-seperator {
    display: inline-block;
    vertical-align: middle;
    border-top: 1px dashed #979797;
    width: 40px;
    margin: 10px; }

.content-wrapper {
  margin: 20px 20px;
  z-index: 3;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

/* -------- Style about header -------- */
.header-container {
  background-color: #fff;
  z-index: 5;
  height: 60px;
  padding: 0 20px;
  line-height: 0px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  transition: all 0.1s ease-in-out; }
  .header-container.fixed-header {
    position: fixed;
    width: calc(100% - 260px);
    top: 0;
    right: 0; }
  .header-container .header-right {
    color: #fff;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    height: 100%;
    min-width: 300px;
    max-width: 30%;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end; }
    .header-container .header-right .name-user {
      font-size: 14px;
      margin-left: 10px;
      margin-right: 5px;
      margin-bottom: 0;
      cursor: pointer;
      color: #000;
      display: inline-block;
      vertical-align: middle;
      font-weight: normal;
      max-width: 200px; }
    .header-container .header-right i {
      font-size: 10px;
      cursor: pointer; }
    .header-container .header-right .avatar-box {
      max-width: 300px; }
    .header-container .header-right .popover-header {
      display: inline-block; }
    .header-container .header-right .ant-avatar > img {
      height: 100%; }
  .header-container .header-left {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    height: 100%;
    min-width: calc(100% - 300px);
    max-width: 70%; }
  .header-container .title {
    padding-left: 20px;
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
    font-size: 14px;
    font-family: 'UTMAvo';
    color: #1D4D9D;
    margin: 0;
    max-width: 100%; }
  .header-container .toogle-sidebar-top {
    width: calc(100% - 50px); }
    .header-container .toogle-sidebar-top .wrap-logo {
      width: calc(100% - 30px); }
      .header-container .toogle-sidebar-top .wrap-logo .title {
        max-width: calc(100% - 30px); }
  .header-container .header-left .title {
    max-width: calc(100% - 30px); }
  .header-container .btn-menu {
    vertical-align: middle;
    color: #000;
    font-size: 18px;
    cursor: pointer;
    transition: color 0.2s; }
    .header-container .btn-menu.icon-menu::before {
      color: #000; }

.title-role {
  background-color: #fff;
  text-align: left; }

#showPopover .ant-popover-inner-content {
  width: 200px;
  padding: 12px 0; }
  #showPopover .ant-popover-inner-content div {
    padding: 10px 15px 10px 15px;
    border-bottom: 1px solid #F5F5F5; }
    #showPopover .ant-popover-inner-content div:last-child {
      border-bottom: none; }
  #showPopover .ant-popover-inner-content .name-role {
    font-size: 12px;
    color: #292A2D;
    letter-spacing: 0;
    line-height: 17px;
    background-color: #F7F7F7;
    position: relative;
    cursor: pointer;
    padding: 10px 15px; }
    #showPopover .ant-popover-inner-content .name-role .icon-left {
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%); }
    #showPopover .ant-popover-inner-content .name-role .name {
      margin-left: 20px;
      display: inline-block;
      width: 140px; }
    #showPopover .ant-popover-inner-content .name-role .icon-right {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%); }
    #showPopover .ant-popover-inner-content .name-role:hover {
      background-color: #fff;
      color: #0275D8; }
    #showPopover .ant-popover-inner-content .name-role.role-active {
      color: #0275D8;
      background-color: #fff; }

.menu-footer {
  display: flex;
  justify-content: space-between; }

.menu-footer-item {
  display: inline;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  padding: 0 !important;
  cursor: pointer;
  border-bottom: none !important; }

.menu-footer-item:hover {
  font-weight: bold; }

/* -------- Style about sidebar -------- */
.sidebar-container {
  z-index: 4;
  transition: all 0.1s ease-in-out; }
  .sidebar-container.fixed-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%; }
    @media only screen and (max-width: 1199px) {
      .sidebar-container.fixed-sidebar {
        z-index: 6;
        box-shadow: 1px 1px 7px -2px rgba(0, 0, 0, 0.75); }
        .sidebar-container.fixed-sidebar.ant-layout-sider-collapsed {
          box-shadow: none; } }

.list-menu-sidebar {
  height: calc(100vh - 53px);
  overflow-y: auto;
  overflow-x: hidden; }
  .list-menu-sidebar .icon {
    padding: 10px;
    min-width: 14px;
    font-size: 14px;
    transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .list-menu-sidebar .icon.icon-antd {
      margin: 0;
      color: #b0c1dd; }
    .list-menu-sidebar .icon + span {
      opacity: 1;
      transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .list-menu-sidebar .icon::before {
      color: #b0c1dd; }
  .list-menu-sidebar.ant-menu-dark {
    background-color: #1D4D9D !important; }
    .list-menu-sidebar.ant-menu-dark .ant-menu-sub {
      box-shadow: none;
      background-color: #1D4D9D !important; }
      .list-menu-sidebar.ant-menu-dark .ant-menu-sub .ant-menu-item.break-line {
        height: 40px;
        line-height: 22px;
        white-space: pre-line; }
  .list-menu-sidebar.ant-menu-inline-collapsed .ant-menu-item .icon,
  .list-menu-sidebar.ant-menu-inline-collapsed .ant-menu-submenu-title .icon {
    margin: 0;
    font-size: 16px;
    line-height: 40px;
    margin-right: 25px;
    padding: 0; }
  .list-menu-sidebar.ant-menu-inline-collapsed > .ant-menu-item {
    padding: 0 20px !important; }
  .list-menu-sidebar.ant-menu-inline-collapsed > .ant-menu-submenu {
    margin: 0; }
    .list-menu-sidebar.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
      padding: 0 20px !important; }
  .list-menu-sidebar.ant-menu-inline-collapsed .ant-menu-item {
    border-left: 0; }
  .list-menu-sidebar .ant-menu-item-selected {
    background-color: #0B3D91 !important; }
    .list-menu-sidebar .ant-menu-item-selected .icon.icon-antd {
      color: #fff; }
    .list-menu-sidebar .ant-menu-item-selected .icon::before {
      color: #fff; }
  .list-menu-sidebar .ant-menu-submenu-selected .ant-menu-submenu-title .icon.icon-antd {
    color: #fff; }
  .list-menu-sidebar .ant-menu-submenu-selected .ant-menu-submenu-title .icon::before {
    color: #fff; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/family/Roboto-BoldItalic.eot");
  src: url("../fonts/family/Roboto-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/family/Roboto-BoldItalic.woff2") format("woff2"), url("../fonts/family/Roboto-BoldItalic.woff") format("woff"), url("../fonts/family/Roboto-BoldItalic.ttf") format("truetype"), url("../fonts/family/Roboto-BoldItalic.svg#Roboto-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/family/Roboto-Light.eot");
  src: url("../fonts/family/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/family/Roboto-Light.woff2") format("woff2"), url("../fonts/family/Roboto-Light.woff") format("woff"), url("../fonts/family/Roboto-Light.ttf") format("truetype"), url("../fonts/family/Roboto-Light.svg#Roboto-Light") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/family/Roboto-Bold.eot");
  src: url("../fonts/family/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/family/Roboto-Bold.woff2") format("woff2"), url("../fonts/family/Roboto-Bold.woff") format("woff"), url("../fonts/family/Roboto-Bold.ttf") format("truetype"), url("../fonts/family/Roboto-Bold.svg#Roboto-Bold") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/family/Roboto-Medium.eot");
  src: url("../fonts/family/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/family/Roboto-Medium.woff2") format("woff2"), url("../fonts/family/Roboto-Medium.woff") format("woff"), url("../fonts/family/Roboto-Medium.ttf") format("truetype"), url("../fonts/family/Roboto-Medium.svg#Roboto-Medium") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/family/Roboto-Regular.eot");
  src: url("../fonts/family/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/family/Roboto-Regular.woff2") format("woff2"), url("../fonts/family/Roboto-Regular.woff") format("woff"), url("../fonts/family/Roboto-Regular.ttf") format("truetype"), url("../fonts/family/Roboto-Regular.svg#Roboto-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/family/Roboto-Italic.eot");
  src: url("../fonts/family/Roboto-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/family/Roboto-Italic.woff2") format("woff2"), url("../fonts/family/Roboto-Italic.woff") format("woff"), url("../fonts/family/Roboto-Italic.ttf") format("truetype"), url("../fonts/family/Roboto-Italic.svg#Roboto-Italic") format("svg");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'UTMAvo';
  src: url("../fonts/family/UTMAvoBold.eot");
  src: url("../fonts/family/UTMAvoBold.eot?#iefix") format("embedded-opentype"), url("../fonts/family/UTMAvoBold.woff2") format("woff2"), url("../fonts/family/UTMAvoBold.woff") format("woff"), url("../fonts/family/UTMAvoBold.ttf") format("truetype"), url("../fonts/family/UTMAvoBold.svg#UTMAvoBold") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Mulish';
  src: url("../fonts/family/Mulish-Black.woff2") format("woff2"), url("../fonts/family/Mulish-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Mulish';
  src: url("../fonts/family/Mulish-BlackItalic.woff2") format("woff2"), url("../fonts/family/Mulish-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Mulish';
  src: url("../fonts/family/Mulish-Bold.woff2") format("woff2"), url("../fonts/family/Mulish-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Mulish';
  src: url("../fonts/family/Mulish-ExtraLightItalic.woff2") format("woff2"), url("../fonts/family/Mulish-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Mulish';
  src: url("../fonts/family/Mulish-Italic.woff2") format("woff2"), url("../fonts/family/Mulish-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Mulish';
  src: url("../fonts/family/Mulish-ExtraBold.woff2") format("woff2"), url("../fonts/family/Mulish-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Mulish';
  src: url("../fonts/family/Mulish-BoldItalic.woff2") format("woff2"), url("../fonts/family/Mulish-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Mulish';
  src: url("../fonts/family/Mulish-ExtraBoldItalic.woff2") format("woff2"), url("../fonts/family/Mulish-ExtraBoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Mulish';
  src: url("../fonts/family/Mulish-ExtraLight.woff2") format("woff2"), url("../fonts/family/Mulish-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Mulish';
  src: url("../fonts/family/Mulish-MediumItalic.woff2") format("woff2"), url("../fonts/family/Mulish-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Mulish';
  src: url("../fonts/family/Mulish-Medium.woff2") format("woff2"), url("../fonts/family/Mulish-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Mulish';
  src: url("../fonts/family/Mulish-Regular.woff2") format("woff2"), url("../fonts/family/Mulish-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Mulish';
  src: url("../fonts/family/Mulish-SemiBold.woff2") format("woff2"), url("../fonts/family/Mulish-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Mulish';
  src: url("../fonts/family/Mulish-LightItalic.woff2") format("woff2"), url("../fonts/family/Mulish-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Mulish';
  src: url("../fonts/family/Mulish-Light.woff2") format("woff2"), url("../fonts/family/Mulish-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Mulish';
  src: url("../fonts/family/Mulish-SemiBoldItalic.woff2") format("woff2"), url("../fonts/family/Mulish-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon/icomoon.eot?rord3b");
  src: url("../fonts/icomoon/icomoon.eot?rord3b#iefix") format("embedded-opentype"), url("../fonts/icomoon/icomoon.ttf?rord3b") format("truetype"), url("../fonts/icomoon/icomoon.woff?rord3b") format("woff"), url("../fonts/icomoon/icomoon.svg?rord3b#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-xem-dang-bieu-do:before {
  content: "\e900";
  color: #fff; }

.icon-tim-kiem:before {
  content: "\e901";
  color: #fff; }

.icon-xoa:before {
  content: "\e902";
  color: #f30808; }

.icon-tien-do-du-an:before {
  content: "\e903"; }

.icon-tai-xuong-2:before {
  content: "\e904";
  color: #5c6376; }

.icon-thong-tin-nguoi-dung:before {
  content: "\e905";
  color: #5c6376; }

.icon-thong-tin-du-an:before {
  content: "\e906"; }

.icon-them-moi:before {
  content: "\e907";
  color: #fff; }

.icon-phone:before {
  content: "\e908";
  color: #ffc551; }

.icon-tai-xuong:before {
  content: "\e909"; }

.icon-quan-ly-danh-muc:before {
  content: "\e90a";
  color: #5c6376; }

.icon-quan-ly-thong-tin:before {
  content: "\e90b";
  color: #5c6376; }

.icon-quan-ly-du-an:before {
  content: "\e90c";
  color: #5c6376; }

.icon-quan-tri-he-thong:before {
  content: "\e90d";
  color: #5c6376; }

.icon-tim-kiem-lai:before {
  content: "\e90e";
  color: #5c6376; }

.icon-tao-moi-bao-cao:before {
  content: "\e90f"; }

.icon-quan-ly-van-ban:before {
  content: "\e910";
  color: #5c6376; }

.icon-thong-ke-bao-cao:before {
  content: "\e911";
  color: #5c6376; }

.icon-menu:before {
  content: "\e912";
  color: #fff; }

.icon-quan-ly-tien-do-du-an:before {
  content: "\e913";
  color: #5c6376; }

.icon-phe-duyet-tai-lieu:before {
  content: "\e914";
  color: #5c6376; }

.icon-luu:before {
  content: "\e915";
  color: #fff; }

.icon-chon-thoi-gian-bao-cao:before {
  content: "\e916";
  color: #5c6376; }

.icon-date:before {
  content: "\e917"; }

.icon-in:before {
  content: "\e918";
  color: #f4f7fb; }

.icon-arrow:before {
  content: "\e919";
  color: #5c6376; }

.icon-chon:before {
  content: "\e91a";
  color: #fff; }

.icon-chon-tai-lieu:before {
  content: "\e91b";
  color: #5c6376; }

.icon-cap-nhat:before {
  content: "\e91c";
  color: #fff; }

.icon-huy:before {
  content: "\e91d";
  color: #5c6376; }

.icon-arrow-fill:before {
  content: "\e91e";
  color: #0065c7; }

/* -------- Style của trang chủ -------- */
.trangchu-page .card-content {
  text-align: center;
  width: 100%;
  height: 100%; }
  .trangchu-page .card-content .tailieu-text {
    height: 50px; }

.trangchu-page .taiLieu-card {
  min-height: 370px;
  width: 100%; }
  .trangchu-page .taiLieu-card .ant-card-body {
    height: 100%; }

.trangchu-page .application-wrapper {
  padding: 10px 20px 0 10px;
  margin-bottom: 20px; }

.trangchu-page .application-block .ant-card-cover {
  min-height: 264px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #AFDFFC; }

.trangchu-page .application-block .img {
  background-color: transparent;
  margin: 0;
  border-radius: 2px 2px 0 0;
  border: 0;
  width: 100%;
  height: auto;
  max-width: 210px; }
  .trangchu-page .application-block .img img {
    height: auto; }
  .trangchu-page .application-block .img .ant-avatar-string {
    font-size: 50px;
    top: 50%;
    transform: scale(1) translate(-50%, -50%) !important;
    padding: 20px;
    word-break: break-all;
    width: 100%;
    white-space: normal;
    line-height: 1em; }

.trangchu-page .application-block .ant-card-body {
  padding: 11px; }

.trangchu-page .application-block .text {
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  color: #0275D8;
  width: 100%; }

.hide-lefttitle-danhsachchucdanh .ant-transfer-list-header-title {
  font-weight: bold; }

.bt-goihethong {
  margin-bottom: 20px; }

.aligntextForm {
  flex-direction: row !important; }

.aligntextForm .ant-form-item-label {
  width: 27%;
  place-self: flex-start;
  line-height: 2.7; }

.aligntextForm.ant-form-item-control {
  width: 50%;
  place-self: flex-end; }

/* -------- Style cho notfound page -------- */
.not-found-page {
  text-align: center;
  width: 500px;
  height: 400px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -200px 0 0 -250px;
  background-color: transparent;
  box-shadow: none;
  border: none; }

.doimatkhau {
  text-align: right; }

.title-uppercase .heading2 {
  text-transform: uppercase; }

.dropdown-action {
  min-width: 132px;
  text-align: left; }
  .dropdown-action.green {
    background-color: #169B4B;
    border-radius: 3px;
    color: #fff;
    height: 32px;
    line-height: 32px;
    padding: 0 15px; }
    .dropdown-action.green.ant-dropdown-open {
      background-color: #27C064; }
      .dropdown-action.green.ant-dropdown-open .anticon {
        transform: rotate(-180deg); }
  .dropdown-action .anticon {
    margin: 0px;
    margin-top: -5px;
    position: absolute;
    right: 15px;
    top: 50%;
    transition: 0.2s; }

.select-disabled-custom.ant-select-multiple .ant-select-selection-item {
  background: #fff !important;
  color: #757575 !important; }

.table-button-custom {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px; }
  @media only screen and (max-width: 575px) {
    .table-button-custom {
      flex-direction: column; }
      .table-button-custom .ant-table-wrapper.table-wrapper-custom {
        width: 100% !important;
        margin-bottom: 10px; } }

/* -------- Style cho các page thuộc auth -------- */
/* -------- Style của login page -------- */
.auth-layout-container {
  background-image: url("./images/bg.png");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto; }
  .auth-layout-container .background {
    height: 100%;
    z-index: 2;
    overflow: auto; }
  .auth-layout-container .wrapper-heading {
    display: flex;
    padding: 60px 0 40px 0;
    align-items: center;
    justify-content: center; }
    @media only screen and (max-width: 1440px) {
      .auth-layout-container .wrapper-heading {
        padding: 40px 0 15px 0; } }
    .auth-layout-container .wrapper-heading .img {
      width: 74px; }
    .auth-layout-container .wrapper-heading .heading {
      display: flex;
      padding-left: 27px;
      flex-direction: column;
      align-items: flex-start; }
      .auth-layout-container .wrapper-heading .heading .heading1 {
        font-size: 25px;
        text-align: center;
        color: #fff;
        font-family: 'UTMAvo';
        text-transform: uppercase; }
      .auth-layout-container .wrapper-heading .heading .heading2 {
        font-size: 18px;
        text-align: left;
        line-height: 30px;
        color: #fff;
        font-family: 'UTMAvo';
        text-transform: uppercase; }
  .auth-layout-container .logo-heading {
    width: 120px;
    margin: 0 auto;
    padding: 90px 0 25px; }
    .auth-layout-container .logo-heading .img {
      width: 120px; }
  .auth-layout-container .heading2 {
    font-size: 20px;
    text-align: center;
    line-height: 30px;
    color: #fff;
    font-family: 'UTMAvo';
    text-transform: uppercase; }
  .auth-layout-container .heading-block .link {
    margin-bottom: 30px;
    display: inline-block;
    font-size: 16px;
    color: #373A3C;
    letter-spacing: 0;
    text-align: left;
    line-height: 21px; }
  .auth-layout-container .btn-link {
    line-height: 2; }
  .auth-layout-container .ant-form-item {
    margin: 5px 0; }
  .auth-layout-container .ant-form-item-label label,
  .auth-layout-container .form-item-span {
    font-weight: 400; }
  .auth-layout-container .footer {
    padding-top: 40px;
    position: absolute;
    margin: 0 auto;
    bottom: 30px;
    text-align: center;
    width: 100%; }
    .auth-layout-container .footer .text {
      color: #fff;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 12px; }

.thongtinnguoidung-page .b-normal-wrapper .body-wrapper {
  padding: 0; }

.thongtinnguoidung-page .form-container .b-avatar-upload {
  padding: 10px 0; }

.thongtinnguoidung-page .form-container .not-allowed {
  box-shadow: none; }
  .thongtinnguoidung-page .form-container .not-allowed .ant-checkbox,
  .thongtinnguoidung-page .form-container .not-allowed .ant-checkbox-input {
    cursor: not-allowed; }
  .thongtinnguoidung-page .form-container .not-allowed.ant-input,
  .thongtinnguoidung-page .form-container .not-allowed .ant-checkbox-inner {
    border-color: #D2D6E0;
    cursor: not-allowed; }

.dangnhap-form {
  max-width: 90%;
  width: 450px;
  margin: 30px auto 10px;
  background-color: #fff;
  padding: 30px 40px;
  border-radius: 10px;
  -webkit-box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.3); }
  .dangnhap-form .btn-save {
    background-color: #0275D8;
    width: 100%;
    height: 36px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px; }

.laylaimatkhau-form {
  max-width: 90%;
  width: 450px;
  margin: 30px auto 10px;
  background-color: #fff;
  padding: 30px 40px;
  border-radius: 10px;
  -webkit-box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.3); }
  .laylaimatkhau-form .btn-save {
    background-color: #0275D8;
    height: 38px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px; }

.taomoimatkhau-form {
  max-width: 90%;
  width: 450px;
  margin: 30px auto 10px;
  background-color: #fff;
  padding: 30px 40px;
  border-radius: 10px;
  -webkit-box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.3); }
  .taomoimatkhau-form .btn-save {
    background-color: #0275D8;
    height: 38px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px; }

.separator-box .solic-line {
  width: calc((100% - 43px)/2);
  border-top: 1px solid #CFCFCF;
  display: inline-block;
  vertical-align: middle; }

.separator-box .text {
  color: #C4C8D1;
  vertical-align: middle;
  margin: 0 5px;
  display: inline-block;
  font-size: 14px;
  line-height: 2;
  font-weight: 500; }

.emailstyle {
  display: block; }

.header-page {
  position: fixed;
  display: flex;
  background: #E8F0FE;
  height: 86px;
  width: 100%;
  z-index: 10; }
  .header-page .wrapper-content-logo {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 20px 30px;
    min-width: 330px; }
    .header-page .wrapper-content-logo .img-logo {
      margin-right: 10px; }
    .header-page .wrapper-content-logo .decription-title span {
      font-family: Mulish;
      font-style: normal;
      font-weight: 800;
      font-size: 15px;
      color: #025AF1;
      line-height: 19px;
      text-transform: uppercase; }
    .header-page .wrapper-content-logo .decription-title div {
      font-family: Mulish;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      text-transform: uppercase;
      color: #000; }
  .header-page .wrapper-nav-bar {
    position: relative;
    margin-right: 30px; }
    .header-page .wrapper-nav-bar .nav-bar {
      overflow-x: auto;
      width: 100%;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: flex-end; }
      .header-page .wrapper-nav-bar .nav-bar span.item-menu.active {
        color: #025AF1; }
        .header-page .wrapper-nav-bar .nav-bar span.item-menu.active .strikethrough {
          visibility: visible;
          opacity: 1; }
      .header-page .wrapper-nav-bar .nav-bar::-webkit-scrollbar:horizontal {
        height: 5px; }
      .header-page .wrapper-nav-bar .nav-bar::-webkit-scrollbar, .header-page .wrapper-nav-bar .nav-bar::-webkit-scrollbar-thumb {
        overflow: visible;
        border-radius: 4px; }
      .header-page .wrapper-nav-bar .nav-bar::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2); }
      .header-page .wrapper-nav-bar .nav-bar:hover .cover-bar {
        opacity: 0; }
      .header-page .wrapper-nav-bar .nav-bar .item-menu {
        font-family: Mulish;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        padding-right: 40px;
        white-space: nowrap;
        color: #08317D;
        opacity: 0.6;
        cursor: pointer; }
    .header-page .wrapper-nav-bar .button-login {
      border: 1px solid #025AF1;
      border-radius: 46px;
      padding: 10px 30px;
      background: none;
      font-family: Mulish;
      font-size: 12px;
      font-weight: 800;
      line-height: 15px;
      cursor: pointer;
      color: #025AF1;
      text-transform: uppercase;
      white-space: nowrap; }

.cover-bar {
  position: absolute;
  background: #E8F0FE;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 10px;
  -webkit-transition: all .5s;
  opacity: 1; }

.wrapper-nav-bar:hover .cover-bar {
  -webkit-transition: all .5s; }

.strikethrough {
  transition: all 0.4s;
  width: auto;
  height: 2px;
  background: #025AF1;
  visibility: hidden;
  opacity: 0;
  margin-top: 5px;
  margin-bottom: -10px; }

.footer-page {
  background-color: #EBF2FF; }
  .footer-page .line-border {
    width: 100%;
    border: 1px solid #08317D;
    opacity: 0.1; }
  .footer-page .top-content {
    display: flex;
    padding: 45px 0;
    justify-content: space-between;
    align-items: center; }
    .footer-page .top-content .wrapper-content-logo {
      display: flex;
      align-items: center; }
      .footer-page .top-content .wrapper-content-logo .img-logo {
        width: 44px;
        height: 44px; }
      .footer-page .top-content .wrapper-content-logo .decription-title {
        margin-left: 10px; }
        .footer-page .top-content .wrapper-content-logo .decription-title span {
          font-family: Mulish;
          font-size: 15px;
          font-style: normal;
          font-weight: 800;
          line-height: 19px;
          letter-spacing: 0em;
          color: #025AF1;
          text-align: left;
          text-transform: uppercase; }
        .footer-page .top-content .wrapper-content-logo .decription-title div {
          font-family: Mulish;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 15px;
          letter-spacing: 0em;
          text-align: left; }
    .footer-page .top-content .wrapper-nav-bar .nav-bar {
      display: flex; }
      .footer-page .top-content .wrapper-nav-bar .nav-bar .item-menu {
        margin-left: 40px;
        font-family: Mulish;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #08317D;
        opacity: 0.6;
        cursor: pointer; }
  .footer-page .bottom-content {
    font-family: Mulish;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    color: #08317D;
    padding: 20px; }

.landing-page-container {
  margin-left: 100px;
  margin-right: 100px; }

.introduce {
  background: linear-gradient(180deg, #E8F0FE 0%, rgba(232, 240, 254, 0) 100%);
  padding-top: 150px;
  padding-bottom: 60px; }
  .introduce .wrapper-intro .intro-title {
    font-family: Mulish;
    font-size: 60px;
    font-style: normal;
    font-weight: 900;
    line-height: 75px;
    letter-spacing: 0em;
    max-width: 100%;
    max-width: 425px;
    text-align: left;
    margin-bottom: 30px;
    color: #025AF1;
    width: 100%; }
  .introduce .wrapper-intro .intro-description {
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    max-width: 682px;
    width: 100%; }

.wrapper-block-data-img {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column; }
  .wrapper-block-data-img .img-block-data {
    max-width: 406px;
    width: 100%;
    max-height: 418px;
    margin-bottom: 38px; }
  .wrapper-block-data-img .shadow-block {
    height: 18px;
    max-width: 400px;
    width: 100%;
    background: #025AF1;
    opacity: 0.4;
    filter: blur(30px); }

.use-target {
  background: #EEF4FF; }
  .use-target .title-use {
    font-family: Mulish;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    color: #08317D;
    margin-bottom: 20px; }

.wrapper-frame img {
  width: 100%;
  max-width: 480px; }

.wrapper-content-use-target {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center; }
  .wrapper-content-use-target .wrapper-content .content .description .list {
    list-style: disc;
    opacity: 0.7;
    padding-left: 86px; }
  .wrapper-content-use-target .wrapper-content .content .title {
    display: flex;
    align-items: center; }
    .wrapper-content-use-target .wrapper-content .content .title img {
      width: 50px;
      height: 50px;
      margin-right: 20px; }
    .wrapper-content-use-target .wrapper-content .content .title .text {
      font-family: Mulish;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: left; }

.statistical {
  padding-top: 60px; }
  .statistical .head-title {
    font-family: Mulish;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: 45px;
    letter-spacing: 0em;
    margin-bottom: 40px;
    text-align: center;
    color: #08317D; }
  .statistical .wrapper-content .block {
    border: 1px solid #EBEBEB;
    background: #FBFBFC;
    padding: 30px; }
    .statistical .wrapper-content .block .title {
      font-family: Mulish;
      font-size: 17px;
      font-style: normal;
      font-weight: 800;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: center; }
    .statistical .wrapper-content .block .updated {
      font-family: Mulish;
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: 0.03em;
      text-align: center;
      margin-bottom: 20px;
      opacity: 0.4; }
    .statistical .wrapper-content .block .block-chil {
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 27px 0;
      border-radius: 10px; }
      .statistical .wrapper-content .block .block-chil .number {
        font-family: Mulish;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 44px;
        letter-spacing: 0.03em;
        text-align: center;
        color: #fff; }
      .statistical .wrapper-content .block .block-chil .name {
        font-family: Mulish;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0.03em;
        text-align: center;
        color: #fff; }

.bg-blue {
  background-color: #025AF1; }

.bg-orange {
  background-color: #FFAF4C; }

.bg-green {
  background-color: #00C882; }

.overview-modal {
  padding: 57px 0;
  background-image: url("./images/bg-modal.png");
  background-repeat: no-repeat;
  background-size: cover; }
  .overview-modal .wrapper-content .title {
    font-family: Mulish;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    margin-bottom: 40px; }
  .overview-modal .wrapper-content .image-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #fff; }
    .overview-modal .wrapper-content .image-modal img {
      width: 85%;
      border-radius: 10px; }

.ingredient .wrapper-content {
  margin: 60px 0; }
  .ingredient .wrapper-content .wrapper-image {
    display: flex;
    align-items: center;
    background: #ECF3FE;
    padding: 140px 40px;
    border-radius: 10px;
    justify-content: center;
    height: 100%; }
    .ingredient .wrapper-content .wrapper-image .img-artificial {
      max-width: 484px;
      width: 100%; }
  .ingredient .wrapper-content .content h2 {
    font-family: Mulish;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 30px;
    margin-top: 20px;
    color: #08317D; }
  .ingredient .wrapper-content .content p {
    text-align: left;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    opacity: 0.8; }

.bg-white {
  background-color: #fff; }

@media only screen and (max-width: 1440px) {
  .introduce .wrapper-block-data-img {
    align-items: flex-end; } }

@media only screen and (max-width: 1280px) {
  .header-page .wrapper-nav-bar .nav-bar .item-menu {
    padding-right: 25px; }
  .use-targer {
    padding: 10px 0; }
  .footer-page .top-content .wrapper-nav-bar {
    column-count: 2; }
    .footer-page .top-content .wrapper-nav-bar .nav-bar {
      display: unset; }
      .footer-page .top-content .wrapper-nav-bar .nav-bar .item-menu {
        margin-bottom: 10px;
        white-space: nowrap; } }

@media only screen and (max-width: 1199px) {
  .header-page .wrapper-nav-bar .nav-bar {
    max-width: 700px;
    justify-content: flex-start; } }

@media only screen and (max-width: 991px) {
  .header-page .wrapper-nav-bar .nav-bar {
    max-width: 400px; }
  .use-target {
    padding: 25px 0; }
  .statistical .wrapper-content .block .title {
    height: 39px; }
  .statistical .wrapper-content .block .updated {
    height: 24px;
    margin-bottom: 15px; } }

@media only screen and (max-width: 767px) {
  .landing-page-container {
    margin-left: 15px;
    margin-right: 15px; }
  .header-page .wrapper-content-logo {
    min-width: unset; }
    .header-page .wrapper-content-logo .decription-title {
      display: none; }
  .introduce {
    padding-top: 94px;
    padding-bottom: 60px; }
    .introduce .wrapper-intro .intro-title {
      font-family: Mulish;
      font-size: 36px;
      font-style: normal;
      font-weight: 900;
      line-height: 45px;
      letter-spacing: 0em;
      text-align: left; }
    .introduce .wrapper-intro .intro-description {
      margin-bottom: 30px; }
    .introduce .wrapper-block-data-img {
      height: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      flex-direction: column; }
      .introduce .wrapper-block-data-img img {
        max-width: 300px;
        max-height: 300px; }
      .introduce .wrapper-block-data-img .shadow-block {
        height: 13px; }
  .wrapper-frame {
    text-align: center;
    margin: 20px 0; }
    .wrapper-frame img {
      width: auto;
      height: 293px; }
  .use-target {
    padding-bottom: 40px; }
    .use-target .content {
      margin-left: 2px; }
      .use-target .content .list li {
        font-family: Roboto;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left; }
  .statistical .wrapper-content .block {
    padding: 30px 15px 0px; }
  .statistical .wrapper-content .block-chil {
    margin-bottom: 25px; }
  .ingredient .wrapper-content .content h2 {
    font-size: 26px; }
  .footer-page .top-content .wrapper-nav-bar {
    display: none; } }

@media only screen and (max-width: 575px) {
  .header-page .wrapper-nav-bar .nav-bar {
    max-width: 250px; }
  .introduce .wrapper-block-data-img img {
    max-width: 240px;
    max-height: 240px; }
  .wrapper-frame {
    margin: 40px 0; } }

/* -------- Import tất cả files .scss trong folder src/assets/responsive -------- */
/* -------- GLobal style responsive -------- */
@media (min-width: 540px) {
  .b-container {
    max-width: 600px; } }

@media (min-width: 800px) {
  .b-container {
    max-width: 680px; } }

@media (min-width: 1024px) {
  .container {
    max-width: 900px; } }

@media (min-width: 1199px) {
  .b-container {
    max-width: 1110px; } }

@media (min-width: 1400px) {
  .b-container {
    max-width: 1300px; } }

@media only screen and (max-width: 575px) {
  .wrapper-content-right {
    margin-left: 0; }
  .layout-container.closed-sidebar .fixed-header {
    width: 100%; }
  .layout-container.closed-sidebar .wrapper-content-right {
    margin-left: 0; }
  .text-left-sm {
    text-align: left !important; }
  .heading-search-item {
    max-width: 100% !important; }
  .heading-right {
    text-align: left !important; }
  .b-avatar-upload .ant-avatar {
    display: block; }
  .b-avatar-upload .b-btn-upload {
    padding: 20px 0; }
  .right-pagination {
    text-align: left;
    margin-top: 10px; }
  .heading-tab .heading-tab-item {
    padding: 5px; }
  .heading-tab .heading-tab-seperator {
    width: 10px; }
  .btn-left {
    margin-bottom: 10px; } }

@media only screen and (max-width: 767px) {
  .content-notfound {
    height: auto;
    min-height: auto;
    padding: 70px 0; }
    .content-notfound .center-block {
      position: relative;
      left: unset;
      top: unset;
      transform: none; } }

@media only screen and (max-width: 1440px) {
  .heading-search.multiple .input-large {
    width: 250px; } }

@media only screen and (max-width: 1199px) {
  .wrapper-content-right {
    margin-left: 80px; }
  .heading-right .heading-search .heading-search-item {
    display: inline-block;
    padding-left: 0; }
  .heading-right .heading-search .heading-search-item {
    width: 100%;
    padding: 5px 0; }
  .heading-right .heading-search .b-search {
    display: block;
    margin-right: 0;
    margin-bottom: 5px; } }

/* -------- Header style responsive -------- */
@media only screen and (max-width: 1199px) {
  .header-container.fixed-header {
    width: calc(100% - 60px); }
  .is-tablet.header-container, .is-mobile.header-container {
    border: none;
    box-shadow: none; }
    .is-tablet.header-container .toogle-sidebar-top, .is-mobile.header-container .toogle-sidebar-top {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      width: 100%;
      display: flex;
      align-items: center; }
      .is-tablet.header-container .toogle-sidebar-top .wrap-logo .img-logo, .is-mobile.header-container .toogle-sidebar-top .wrap-logo .img-logo {
        width: 40px;
        margin-left: 20px; }
    .is-tablet.header-container .nav-header-responsive, .is-mobile.header-container .nav-header-responsive {
      position: absolute;
      right: 0;
      top: 0;
      width: 320px;
      height: 100vh;
      transition: width .1s ease;
      background-color: #fff;
      overflow: auto;
      box-shadow: 1px -1px 12px #e8e8e8; }
      .is-tablet.header-container .nav-header-responsive .nav-top, .is-mobile.header-container .nav-header-responsive .nav-top {
        background-color: #fff;
        margin-bottom: 10px;
        line-height: normal; }
        .is-tablet.header-container .nav-header-responsive .nav-top .header-responsive, .is-mobile.header-container .nav-header-responsive .nav-top .header-responsive {
          padding: 15px; }
          .is-tablet.header-container .nav-header-responsive .nav-top .header-responsive .header-close, .is-mobile.header-container .nav-header-responsive .nav-top .header-responsive .header-close {
            cursor: pointer;
            position: absolute;
            top: 15px;
            right: 15px;
            font-size: 15px;
            color: #9B9B9B; }
          .is-tablet.header-container .nav-header-responsive .nav-top .header-responsive .img-logo, .is-mobile.header-container .nav-header-responsive .nav-top .header-responsive .img-logo {
            width: 40px; }
          .is-tablet.header-container .nav-header-responsive .nav-top .header-responsive .title, .is-mobile.header-container .nav-header-responsive .nav-top .header-responsive .title {
            color: #0275D8;
            padding-left: 10px;
            font-size: 11px;
            max-width: 230px;
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 0; }
        .is-tablet.header-container .nav-header-responsive .nav-top .info-user, .is-mobile.header-container .nav-header-responsive .nav-top .info-user {
          padding: 5px 15px; }
          .is-tablet.header-container .nav-header-responsive .nav-top .info-user .info-username, .is-mobile.header-container .nav-header-responsive .nav-top .info-user .info-username {
            font-size: 14px;
            margin-left: 10px;
            margin-bottom: 0;
            color: #000;
            display: inline-block;
            vertical-align: middle;
            font-weight: normal;
            max-width: 200px; }
        .is-tablet.header-container .nav-header-responsive .nav-top .info-role, .is-mobile.header-container .nav-header-responsive .nav-top .info-role {
          padding: 10px 0 15px 0; }
          .is-tablet.header-container .nav-header-responsive .nav-top .info-role .title-role, .is-mobile.header-container .nav-header-responsive .nav-top .info-role .title-role {
            padding: 0 15px;
            font-size: 12px;
            color: #828487;
            margin-bottom: 5px; }
          .is-tablet.header-container .nav-header-responsive .nav-top .info-role .list-roles .role-item, .is-mobile.header-container .nav-header-responsive .nav-top .info-role .list-roles .role-item {
            font-size: 12px;
            color: #292A2D;
            letter-spacing: 0;
            line-height: 17px;
            background-color: #F7F7F7;
            cursor: pointer;
            font-size: 13px;
            padding: 10px 15px; }
            .is-tablet.header-container .nav-header-responsive .nav-top .info-role .list-roles .role-item .role-item-icon,
            .is-tablet.header-container .nav-header-responsive .nav-top .info-role .list-roles .role-item .role-item-title, .is-mobile.header-container .nav-header-responsive .nav-top .info-role .list-roles .role-item .role-item-icon,
            .is-mobile.header-container .nav-header-responsive .nav-top .info-role .list-roles .role-item .role-item-title {
              display: inline-block;
              vertical-align: middle; }
            .is-tablet.header-container .nav-header-responsive .nav-top .info-role .list-roles .role-item .role-item-icon, .is-mobile.header-container .nav-header-responsive .nav-top .info-role .list-roles .role-item .role-item-icon {
              width: 15px;
              margin-right: 10px; }
            .is-tablet.header-container .nav-header-responsive .nav-top .info-role .list-roles .role-item.role-active, .is-tablet.header-container .nav-header-responsive .nav-top .info-role .list-roles .role-item:hover, .is-mobile.header-container .nav-header-responsive .nav-top .info-role .list-roles .role-item.role-active, .is-mobile.header-container .nav-header-responsive .nav-top .info-role .list-roles .role-item:hover {
              color: #0275D8;
              background-color: #fff; }
            .is-tablet.header-container .nav-header-responsive .nav-top .info-role .list-roles .role-item .icon-right, .is-mobile.header-container .nav-header-responsive .nav-top .info-role .list-roles .role-item .icon-right {
              position: absolute;
              right: 15px; }
      .is-tablet.header-container .nav-header-responsive .nav-bottom, .is-mobile.header-container .nav-header-responsive .nav-bottom {
        background-color: #fff;
        padding: 20px 50px;
        height: 100%;
        border-top: 4px solid #e8e8e8;
        display: flex;
        justify-content: space-between; } }

@media only screen and (max-width: 575px) {
  .header-container.fixed-header {
    width: 100%; }
  .is-tablet.header-container .toogle-sidebar-top .title, .is-mobile.header-container .toogle-sidebar-top .title {
    display: none; }
  .is-tablet.header-container .nav-header-responsive, .is-mobile.header-container .nav-header-responsive {
    width: 100%; } }

/* -------- Sidebar style responsive -------- */
/* -------- Pages style responsive -------- */
